/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable  react/jsx-filename-extension  */
/* eslint-disable comma-dangle */

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { IntlProvider } from 'react-intl';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from 'material-ui-pickers';
import MomentUtils from 'material-ui-pickers/utils/moment-utils';
import 'react-virtualized/styles.css';
import { defaultLocale } from 'Helpers/NumberHelpers';
import { AuthRouterMode, AuthRouterProvider } from '@kpmg-uk/auth-router-react/dist';
import { theme } from './Style/Theme';
import configureStore from './Store/ConfigureStore';
import App from './App';
import { unregister } from './registerServiceWorker';
import './index.css';
import { USER_GET_CLIENT_CONFIGURATION } from './Store/Api/ApiEndpoints';
import EncryptedLocalStorage from './Store/EncryptedLocalStorage';

export const secureLocalStorage = new EncryptedLocalStorage(Storage);

// Get base url for calling api in development - this is handled by cloudfront in production
export const apiUrl = process.env.NODE_ENV === 'development' ? process.env.REACT_APP_API_URL : 'https://APPLE-TEST-api.kpmgtaxanalytics.com';
// Create browser history to use in the Redux store
export const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');

const history = createBrowserHistory({ basename: baseUrl });

// Create a dnd preview image to hide the default browser implementation
// Passed down via context to avoid creating a new DOM element for each
// DND element
const img = new Image(1, 1);
document.body.appendChild(img);
export const DndPreviewContext = React.createContext(img);

const localStorageKeys = Object.keys(localStorage);
const sessionStorageKeys = Object.keys(sessionStorage);

if (localStorageKeys.some(x => x.includes('adal.') || sessionStorageKeys.some(y => y.includes('adal.')))) {
  localStorage.clear();
  sessionStorage.clear();
}

Storage.prototype.setItem = secureLocalStorage.setItem;
Storage.prototype.getItem = secureLocalStorage.getItem;
Storage.prototype.removeItem = secureLocalStorage.removeItem;

const getConfig = async () => {
  const response = await fetch(`${apiUrl}/${USER_GET_CLIENT_CONFIGURATION}`);
  if (response.ok) {
    const clientConfig = await response.json();
    return {
      authRouterConfig: {
        authority: clientConfig.host,
        client_id: clientConfig.clientId,
        redirect_uri: `${clientConfig.redirectUri}/signin-oidc`,
        automaticSilentRenew: true,
        monitorSession: true,
        mode: AuthRouterMode.KpmgGlobalAdOktaBasedOnEmail,
        extraQueryParams: {
          ApiScopesRequired: true,
          UserMigrationCallBackUrl: clientConfig.userMigrationCallBackUrl
        },
        // logout user from Auth Router & IDP, cleanup
        onRemoveUser: (email) => {
          const logoutUrl = `${clientConfig.host}/AuthRouter/logout?clientId=${clientConfig.clientId}&email=${email}`;
          window.location.assign(logoutUrl);
        },

        // navigate user to homepage after successful sign in & remove params
        onSigninCallback: () => {
          window.history.replaceState({}, document.title, clientConfig.redirectUri);
        },
      },
      signalRKeepAliveIntervalInSeconds: clientConfig.signalRKeepAliveIntervalInSeconds,
      currentVersion: clientConfig.currentVersion,
    };
  }
  return null;
};

getConfig().then(({ authRouterConfig, signalRKeepAliveIntervalInSeconds, currentVersion }) => {
  // Get the application-wide store instance, prepopulating with state
  // from the server where available.
  const store = configureStore(history, {
    ...window.initialReduxState,
    app: {
      config: {
        authRouterConfig: authRouterConfig,
        clientId: authRouterConfig.client_id,
        host: authRouterConfig.authority,
        redirectUri: authRouterConfig.redirect_uri,
        signalRKeepAliveIntervalInSeconds,
        currentVersion,
      }
    }
  });

  ReactDOM.render(
    <AuthRouterProvider {...authRouterConfig}>
      <IntlProvider locale={defaultLocale}>
        <Provider store={store}>
          <ConnectedRouter history={history}>
            <MuiThemeProvider theme={theme}>
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <DndPreviewContext.Provider value={img}>
                  <App />
                </DndPreviewContext.Provider>
              </MuiPickersUtilsProvider>
            </MuiThemeProvider>
          </ConnectedRouter>
        </Provider>
      </IntlProvider>
    </AuthRouterProvider>,
    document.getElementById('root')
  );
}, false);
unregister();
